

import { Vue, Component } from 'vue-property-decorator'
import { FileInfo } from '@/types/uploadFile'
@Component({
  name: 'OperationRecordView',
  filters: {
    previewList: function (value: FileInfo[]) {
      // console.log('value', value)
      return value.map((item: { filePrefix: string; fileUrl: string }) => {
        return item.filePrefix + item.fileUrl
      })
    },
    previewList2: function (value: FileInfo[]) {
      return value.map((item: any) => {
        return item.imgUrl
      })
    }
  }
})
export default class OperationRecordView extends Vue {
  formData = {
    projectId: '', // 项目id
    dateStr: '', // 经营日期
    absenceNames: '',
    absenceIdList: [], // 缺勤人员id
    attendanceNames: '',
    attendanceIdList: [], // 出勤人员id
    flowOrderList: [], // 工单列表
    machineryList: [], // 机械
    materialList: [], // 材料
    userList: [], // 人员
    planList: []
    // operatingClockList: [] // 考勤图片
  }

  get operatingId () {
    return this.$route.params.operatingId
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.operationmanage.selectOperatingByOperatingId, {
      operatingId: this.operatingId
    }).then(res => {
      this.formData = res || {}
    })
  }

  getUserSummaries (params: any) {
    const sum1 = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.quantity || 0)
    }, 0)
    const sum2 = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.laborCostSubtotal || 0)
    }, 0).toFixed(2)
    const sum3 = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.overtimeHours || 0)
    }, 0)
    const sum4 = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.overtimeCostSubtotal || 0)
    }, 0).toFixed(2)
    const sum5 = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.total || 0)
    }, 0).toFixed(2)
    return ['合计', '', '', sum1, sum2, '', '', sum3, sum4, sum5]
  }

  getMachineSummaries (params: any) {
    const sum = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.total || 0)
    }, 0).toFixed(2)
    return ['合计', '', '', '', '', '', '', sum, '', '']
  }

  getMaterialSummaries (params: any) {
    const sum = params.data.reduce((prev: any, next: any) => {
      return prev + parseFloat(next.total || 0)
    }, 0).toFixed(2)
    return ['合计', '', '', '', '', '', '', sum, '', '']
  }
}
